/* Plans Page Wrapper */
.plans-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    color: #e0e0e0;
    background-color: #121212;
}

/* Page Header */
.plans-page h2 {
    font-size: 36px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 40px;
}

/* Plans Container */
.plans-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
}

/* Individual Plan Card */
.plan-card {
    background-color: #1d1d1d;
    border: 1px solid #333;
    border-radius: 8px;
    padding: 30px 20px;
    min-width: 200px;
    max-width: 250px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Plan Title */
.plan-title {
    font-size: 24px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 10px;
}

/* Plan Price */
.plan-price {
    font-size: 18px;
    color: #e0e0e0;
    margin-bottom: 15px;
}

/* Subscribe Button */
.subscribe-btn {
    padding: 10px 20px;
    background-color: #1CA733;
    color: #121212;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
    background-color: #88d497;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
    .plans-container {
        flex-direction: column;
        gap: 20px;
    }

    .plan-card {
        width: 100%;
        max-width: 300px;
    }
}