.filter-menu {
    position: relative;
}

.filter-trigger {
    font-size: 16px;
    font-weight: bold;
    color: #1CA733;
    cursor: pointer;
    text-align: right;
}

.filter-trigger:hover {
    color: #01D449;
}

.filter-options {
    position: absolute;
    top: 30px; /* Adjust for spacing */
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 55px;
    z-index: 1000;
    min-width: 500px; /* Wider for horizontal layout */
}

/* Horizontal row styling */
.filter-row {
    display: flex;
    gap: 20px; /* Space between columns */
    margin-bottom: 15px;
    flex-wrap: wrap; /* Wrap items on smaller screens */
}

.filter-item {
    flex: 1; /* Ensure items adjust evenly */
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; /* Spacing between rows */
}

.filter-item label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #1CA733;
}

.filter-item select,
.filter-item input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    background-color: #242424bd; /* Darker background for inputs */
    color: #3e9c35; /* Text color for input fields */
    border: 1px solid #5f5f5f;
    border-radius: 4px;
    transition: border-color 0.3s, background-color 0.3s;
}

.filter-item select:focus,
.filter-item input:focus {
    border-color: #3e9c35;
    outline: none;
}

.filter-item select option,
.filter-item input::placeholder {
    color: #1CA733; /* Placeholder text color */
}

/* Submit Button */
.filter-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.submit-button {
    background-color: #3e9c35;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
    background-color: #72de8bcc;
}

.filter-range {
    display: flex;
    gap: 10px; /* Space between min and max inputs */
}

.filter-range input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #5f5f5f;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .filter-options{
        min-width: auto;
    }
}