.unit-bet-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unit-bet-input {
    display: flex;
    gap: 10px;
    align-items: center;
}

.unit-bet-display {
    display: flex;
    gap: 10px;
    align-items: center;
}

.save-button,
.edit-button {
    background-color: #a0e4b0;
    color: #121212;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.save-button:hover,
.edit-button:hover {
    background-color: #82ca9d;
}