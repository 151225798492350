.results-table-container {
    margin: 20px;
    padding: 20px;
    background-color: #202020;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.results-table th, .results-table td {
    border: 1px solid #e5e5e5;
    padding: 8px;
    text-align: center;
}

.results-table th {
    background-color: #1CA733;
    color: white;
}

/* Exclude highlighted cells from alternating row backgrounds */
.results-table tr:nth-child(even) td:not(.green-highlight1):not(.red-highlight1) {
    background-color: #323232;
}

/* Exclude highlighted cells from hover effect background changes */
.results-table tr:hover td:not(.green-highlight1):not(.red-highlight1) {
    background-color: #1CA733;
}

@media (max-width: 768px) {
    /* Make the table container scrollable */
    .results-table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
        padding: 10px; /* Adjust padding for mobile */
        margin: 10px; /* Adjust margins */
    }

    .results-table {
        width: 100%; /* Ensure table width stays within the container */
        min-width: 600px; /* Set a minimum width to avoid collapsing */
        border-collapse: collapse; /* Keep rows aligned */
    }

    .results-table th, 
    .results-table td {
        font-size: 14px; /* Adjust font size for readability */
        padding: 10px; /* Add padding for touch devices */
    }
}

/* Consistent highlight colors with increased specificity */
.results-table tr td.green-highlight1 {
    background-color: rgba(28, 167, 51, 0.2) !important; /* slight green matching header */
}

.results-table tr td.red-highlight1 {
    background-color: rgba(211, 47, 47, 0.2) !important; /* slight red */
}

/* Ensure hover does not override cell-specific styling for highlighted cells */
.results-table tr:hover td.green-highlight1 {
    background-color: rgba(28, 167, 51, 0.2) !important;
}

.results-table tr:hover td.red-highlight1 {
    background-color: rgba(211, 47, 47, 0.2) !important;
}
