.social-links-container {
    width: 100%;
    padding: 20px 40px;
    background-color: #121212;
  }
  
  .social-links {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .social-icon {
    color: #1CA733;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #88d497;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    .social-links-container {
      padding: 15px 20px;
    }
    
    .social-icon {
      font-size: 20px;
    }
  }