/* Settings container */
.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #121212;
    color: #e0e0e0;
    min-height: 100vh;
}

.settings-title {
    color: #a0e4b0;
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
}

.settings-section {
    background-color: #1d1d1d;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    color: #e0e0e0;
    max-width: 500px;
}

.settings-section h3 {
    color: #1CA733;
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
}

/* Form input and button styling */
.settings-section input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #2c2c2c;
    color: #e0e0e0;
}

.settings-section button {
    width: 100%;
    padding: 12px;
    background-color: #a0e4b0;
    color: #121212;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.settings-section button:hover {
    background-color: #1CA733;
}

/* Checkbox styling for notifications */
.settings-section label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #e0e0e0;
}

.settings-section input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2); /* Make checkbox slightly larger */
}

.profile-field {
    margin-bottom: 15px;
}

.profile-field label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #1CA733;
    margin-bottom: 5px;
}

.profile-field span {
    flex: 1;
    color: #e0e0e0;
    background-color: #1d1d1d;
    padding: 10px;
    border-radius: 5px;
}

.profile-field input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #2c2c2c;
    color: #e0e0e0;
}

.profile-field p {
    margin: 0;
    color: #e0e0e0;
    background-color: #2c2c2c;
    padding: 10px;
    border-radius: 5px;
}

.profile-field button {
    width: 100%;
    padding: 10px;
    background-color: #a0e4b0;
    color: #121212;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 5px;
}

.profile-field button:hover {
    background-color: #1CA733;
}