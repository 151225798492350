/* Container */
.daypass-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

/* Card Styling */
.daypass-card {
    position: relative;
    width: 700px; 
    height: 300px;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1d1d1d; /* Dark background */
    box-shadow: 
        0 0 15px #1CA733, /* Green glow effect */
        0 4px 8px rgba(0, 0, 0, 0.6); /* Black outline shadow */
    text-align: center;
    color: #e0e0e0; /* Light text */
    padding: 20px;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
}

/* Hover Effect */
.daypass-card:hover {
    box-shadow: 
        0 0 25px #1CA733, /* Intensified green glow on hover */
        0 8px 16px rgba(0, 0, 0, 0.8); /* Stronger black outline */
    transform: scale(1.02); /* Slight pop effect */
}

/* Title */
.daypass-card h3 {
    font-size: 24px;
    font-weight: bold;
    color: #1CA733; /* Bright green */
    margin-bottom: 10px;
}

/* Price */
.daypass-card strong {
    font-size: 20px;
    font-weight: bold;
    color: #1CA733; /* Green highlight */
    margin-bottom: 10px;
}

/* Description */
.daypass-card p {
    font-size: 16px;
    color: #bdbdbd; /* Light grey text */
    margin-bottom: 15px;
}

/* Features List */
.daypass-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.daypass-card li {
    margin: 5px 0;
    color: #e2e8f0; /* Light text color */
}

/* Button */
.daypass-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    background-color: #1CA733; /* Bright green button */
    color: #121212;
    transition: background-color 0.3s ease, transform 0.2s;
}

.daypass-button:hover {
    background-color: #88d497; /* Lighter green on hover */
    transform: scale(1.1);
}

/* Mobile View */
@media (max-width: 768px) {
    .daypass-card {
        width: 90%; /* Adjust width for smaller screens */
        height: auto;
        padding: 15px;
    }

    .daypass-card h3 {
        font-size: 1.5rem;
    }

    .daypass-card strong {
        font-size: 1.2rem;
    }

    .daypass-button {
        font-size: 12px;
    }
}
