.home-container {
    padding: 20px;
    text-align: center;
    transition: filter 0.3s ease; /* Added transition for smooth blur effect */
}

.blur-background {
    filter: blur(5px); /* Applies blur effect to the home container */
}

.load-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: #0056b3;
}
