/* Navbar Container */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Logo on left, menu icon on right */
    position: relative;
    background-color: #1d1d1d;
    color: #ffffff;
    padding: 10px 20px;
}

/* Logo Styling */
.logo {
    flex: 0 0 auto; /* Keeps logo size fixed */
    display: flex;
    align-items: center;
}

.logo img {
    height: 60px;
}

.logo img.logo-full {
    display: block;
}

.logo img.logo-icon {
    display: none;
}

/* Nav Center (Links) */
.nav-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right:150px;
}

/* Navigation Links */
.nav-links {
    display: flex;
    gap: 30px; /* Spacing between links */
    list-style: none;
    text-align: center;
}

.nav-links li a {
    text-decoration: none;
    color: #1CA733;
    font-size: 18px;
    padding: 5px 10px;
}

.nav-links li a:hover {
    color: #88d497; /* Hover effect */
}

/* Hamburger Menu Icon */
.menu-icon {
    font-size: 24px;
    color: #01D449;
    cursor: pointer;
    display: none; /* Default hidden, visible on mobile */
}

/* Hamburger Menu Hover Effect */
.menu-icon:hover {
    background-color: #333;
}

/* User Dropdown Menu */
.user-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #1d1d1d;
    border: 1px solid #333;
    border-radius: 8px;
    width: 150px;
    list-style: none;
    padding: 10px 0;
    z-index: 10000;
}

.user-dropdown li {
    padding: 10px;
    text-align: left;
}

.user-dropdown li a {
    color: #1CA733;
    text-decoration: none;
}

.user-dropdown li a:hover {
    color: #88d497;
    background-color: #333;
}

/* Sign Out Button */
.sign-out-btn {
    background: none;
    border: none;
    color: #1CA733;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    padding: 10px;
    width: 100%;
}

.sign-out-btn:hover {
    color: #1CA733;
    background-color: #333;
}

/* Mobile View Styles */
@media (max-width: 768px) {
    /* Logo Adjustments */
    .logo img.logo-full {
        display: none; /* Hide full logo */
    }

    .logo img.logo-icon {
        display: block; /* Show smaller icon */
        height: 50px; /* Adjust icon size */
    }

    /* Mobile Hamburger Menu */
    .menu-icon {
        display: block;
        z-index: 1001;
    }

    .menu-icon:hover {
        background-color: #333;
    }

    /* Navbar Adjustments */
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        position: relative;
        background-color: #1d1d1d;
    }

    /* Center Logo */
    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }

    /* Dropdown Menu for Mobile */
    .nav-links {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        height: 20vh;
        background-color: #121212;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        z-index: 3;
    }

    .nav-links.active {
        display: flex; /* Show menu on mobile */
    }
}

/* Desktop View Adjustments */
@media (min-width: 769px) {
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #1d1d1d;
        padding: 10px 20px;
    }

    .nav-center {
        flex-grow: 1; /* Allows links to occupy available space */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-links {
        display: flex;
        gap: 30px;
        list-style: none;
        text-align: center;
    }

    .auth-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
}

/* Button Styles */
.login-btn {
    background-color: transparent;
    color: #1CA733;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.signup-btn {
    background-color: #1CA733;
    color: #121212;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.signup-btn:hover {
    background-color: #1CA733;
}

.login-btn:hover {
    background-color: #333;
}
